<template>
  <Component
    :is="animated ? 'DelayedTransitionGroup' : 'div'"
    :tag="animated ? 'div' : null"
    class="md:tw-gap-7 tw-gap-5 tw-grid">
    <slot />
  </Component>
</template>

<script>
export default {
  name: 'Grid',

  props: {
    animated: {
      type: Boolean,
    },
  },
};
</script>
