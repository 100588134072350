<template>
  <Grid
    animated
    class="tw-auto-rows-auto tw-mx-auto">
    <BrandingCompanyLogo
      v-if="logo"
      key="page__branding-company-logo" />

    <div key="page__content">
      <Transition
        name="fade"
        mode="out-in">
        <Grid
          v-if="!errors.length"
          key="page__content"
          v-test="'content'"
          :class="contentClass">
          <slot />
        </Grid>

        <template v-if="errors.length">
          <ErrorCard
            v-for="(errorData, index) in errors"
            :key="`page-error__${index}`"
            :error="errorData" />
        </template>
      </Transition>
    </div>

    <CompanyFooter key="page__footer" />
  </Grid>
</template>

<script>
import { ApiError } from '@/services/ApiError';
import CompanyFooter from '@/components/CompanyFooter';
import ErrorCard from '@/views/ErrorCard';
import Grid from '@/components/Grid';
import { redirectToCorrectUrl } from '@/router/route-guard/redirectToCorrectUrl';

export default {
  name: 'Page',
  components: {
    Grid,
    BrandingCompanyLogo: () => import('@/components/branding/BrandingCompanyLogo'),
    ErrorCard,
    CompanyFooter,
  },

  props: {
    /**
     * Decides whether the company logo is rendered.
     */
    logo: {
      type: Boolean,
    },

    /**
     * The Vuex actions to run on load and base errors on.
     *
     * @type {string[]}
     */
    actions: {
      type: Array,
      default: () => [],
    },

    /**
     * Class(list) to add to the div that wraps the slot.
     */
    contentClass: {
      type: String,
      default: null,
    },

    error: {
      type: ApiError,
      default: null,
    },
  },

  data() {
    return {
      apiErrors: [],
    };
  },

  computed: {
    errors() {
      const errors = [];

      if (this.error) {
        errors.push(this.error);
      }

      if (this.apiErrors.length) {
        errors.push(...this.apiErrors);
      }

      return errors;
    },
  },

  async beforeMount() {
    if (!this.actions.length) {
      return;
    }

    const fetchedData = await Promise.all(
      this.actions.map((action) => this.$store.dispatch(action)),
    );

    this.apiErrors = fetchedData.filter((data) => data instanceof ApiError);

    if (this.apiErrors.length) {
      return;
    }

    const redirected = redirectToCorrectUrl(fetchedData);

    // Self-destruct when user is being redirected to avoid loading the rest of the page.
    if (redirected) {
      this.$el.remove();
      this.$destroy();
    }
  },
};
</script>
